.cheveron {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    //margin: 0 25px 14px 143px;
    //object-fit: contain;
}

.flipVertical {
    transform: scaleY(-1);
}