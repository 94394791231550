.header {

  height: 150px;

  padding-top:42px;
  padding-bottom:22px;

  background-color: var(--primary);

  //border-bottom: 1px solid var(--accent-background); todo remove this from AMI also
}

.logoImg {
  &:hover {
    cursor: pointer;
  }
}