/* =============================================================================
   Primary Styles
   Author: rsacek
   ========================================================================== */





/*
  Imports
  Modify Bootstrap defaults...

  Note: This is duplicated below but we have mixed scss variables and css variables so its probably ok but could be cleaned up farther. Maybe Scss vars set our css vars?
*/
$enable-validation-icons: false;
$input-placeholder-color: var(--secondary);
$modal-backdrop-bg: rgba(11, 11, 11, 1.0);



@import "~bootstrap/scss/bootstrap";
//@import "fonts/FontAwesome/solid";



/*

** z-index usage **

1     Key Cards Backdrop over card
2     Key Cards Overlay image.

1     SpeakerProfileModal - close button (top/right)

499 Fixed Right Floating button


500  CategoryFilterSelection - Full screen mask - NEEDS TO BE HIGH (1000)
501  CategoryFilterSelection - Menu, sits above fullscreen mask.   (1001)

500  FilterSelectionGeneric - Full screen mask - NEEDS TO BE HIGH  (1000)
501  FilterSelectionGeneric - Menu, sits above fullscreen mask.    (1001)


1040  Backdrop     - Used by SubmitOverlay
1040  Large Spinner


600  ISI Product floating bottom fixed popup
601  Cookie Consent - Footer

602 Return To Top floating button


500    Header - fullNavBarMask      - Full Nav Mask
501    Header - dropdown            - Full Nav Top Menu Items (In top nav bar)
502    Header - fullNavbar.dropdown - Full Nav Opened Menu with items.
502    Header - dropdownContent     - Item in drop down.

500    Header - miniMenuMask        - Mask
501    Header - miniMenuSection     - Mini menu opened

// OLD 99    Header - fullNavBarMask   1500      - 500 - Full Nav Mask
// OLD 100   Header - dropdown         1510      - 501 - Full Nav Top Menu Items
// OLD 200   Header - fullNavbar.dropdown - 1520 - 502 - Full Nav Opened Menu with items.
//OLD 1     Header - dropdownContent  1520      - 502

// OLD 99    Header - miniMenuMask     500  - Mask
// OLD 1100  Header << miniMenuSection 501  - Mini menu opened


1     WistiaManager - ???
9000  ServiceWorkerWrapper - NOT USED


$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;

For CustomAlert use z-index starting at 1051+

 */






/* =============================================================================
   Fonts
   ========================================================================== */


@font-face {
  font-family: 'BeatriceLight';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Light/Beatrice-Light.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff2') format('woff2'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff') format('woff'),
          url('fonts/Beatrice_Light/Beatrice-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}

@font-face {
  font-family: 'BeatriceRegular';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Regular/Beatrice-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff2') format('woff2'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff') format('woff'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}


/* =============================================================================
   Variables

   ========================================================================== */
:root {
  //Colors
  --default-text:#212529; //todo Text seem to be white or black in Deep

  --white:#fff; // still need white for things in both designs not sure how this will shake out
  --black:#000; // text that uses this should probably be default-text
  // these are in this format to support fading / animation
  --light-gray:rgba(233,236,242, 1.0); //not sure what to call this one. Used for hover highlights and background adding it to things to be left alone for now
  --accent-background: rgba(233,236,242, 1.0);

  --primary:#0b0b17;
  --primary-light:#171726;

  --secondary:#528afe;
  --secondary-light:#e9ecf2;
  --secondary-dark:var(--secondary); //no Concept seems present in Deep so just pointing it the secondary
  --accent:#e81783;
  --accent-dark:#4f0f70;

  --banner-bg: linear-gradient(306deg, var(--accent-dark) 33%, var(--accent));

  // shadows definitions
  --shadow-base: 0, 0, 0;
  --shadow-alpha: 0.52;
  --shadow-alpha-light: 0.18;
  --box-shadow-dark: 1px 3px 13px 0 rgba(var(--shadow-base), var(--shadow-alpha));
  --box-shadow-light: 1px 3px 13px 0 rgba(var(--shadow-base), var(--shadow-alpha-light));

  --isi-cookie-inset-box-shadow: inset 7px 10px 12px #f0f0f0;

  --error: red;
}


/* =============================================================================
   Context Colors Classes
   ========================================================================== */

/* =============================================================================
   Text Colors
   ========================================================================== */


.white{
  color: var(--white);
}

//This color is so subtly different from the main color that im not sure its needed (used in the footer and media Card)
.black {
  color: var(--black);
}

.secondary-text {
  color: var(--secondary);
}

.accent-text {
  color: var(--accent);
}

//todo change name on AMI too
.section-header-text {
  color: var(--white);
}

.error-color { //I think Error is ok
  color: var(--error);
}

/* =============================================================================
   Background / Divider Colors
   ========================================================================== */

.default-bg { //todo this can be batter in ami too Used lots of places
  background-color: var(--primary) !important;
  background-image: linear-gradient(to top, var(--primary-light), var(--primary) 1000px);
}

.divider-default-bg {
  background-color: var(--white);
}

.divider-accent-bg {
  background-color: var(--accent-background) !important;
}

/* =============================================================================
   Global
   ========================================================================== */


html {
  height: 100%;
  background-color: var(--primary);
}

*:focus, textarea:focus, input:focus, button:focus {
  outline: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color:var(--white);
  background-color: var(--primary);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Default Font
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  //margin-top: 76px;
  //padding-top: 76px;
  // margin-top: calc( env(safe-area-inset-top, 0px) + 76px); // RJS fullscreen fix - ipad does not adjust.

  //margin-bottom: 96px;
  //padding-bottom: 57px;

  &.no-overflow {
    overflow-x: hidden;
  }
}


/* =============================================================================
   Typography
   ========================================================================== */

.header-base {
  font-family: "Nunito Sans", Helvetica, sans-serif;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1, .h1 {
  @extend .header-base;
  font-size: 55px;
  line-height: 1.18;
}

h2, .h2 {
  @extend .header-base;
  font-size: 45px;
  line-height: 1.19;
}

h3, .h3 {
  @extend .header-base;
  font-size: 36px;
  line-height: 1.31;
}

h4, .h4 {
  @extend .header-base;
  font-size: 24px;
  line-height: 1.36;
}

.para-base-type-1 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.p-large {
  @extend .para-base-type-1;
  font-size: 20px;
  line-height: 1.4;
}

.p-regular {
  @extend .para-base-type-1;
  font-size: 18px;
  line-height: 1.44;
}

.p-small {
  @extend .para-base-type-1;
  font-size: 16px;
  line-height: 1.13;
}

.p-extra-small {
  @extend .para-base-type-1;
  font-size: 14px;
  line-height: 1.43;
}

// only used in Territory metric report but seems like it could have been used else where
.p-micro-light {
  @extend .para-base-type-1;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.43;
}

.para-base-type-2 {
  font-family: BeatriceLight, Helvetica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.testimonials-text {
  @extend .para-base-type-2;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

em {
  font-weight: bold;//500;
  font-style: normal;
}

strong {
  font-weight: bold;//500;
}

.bold {
  font-weight: 500 !important;
}

.bolder {
  font-weight: 700 !important;
}

.light {
  font-weight: 300 !important;
}

//p {
//
//  &.lg {
//  }
//
//  &.md {
//  }
//
//	&.sm {
//	}
//}
//
a {

  color: var(--secondary);

  &:hover {
    color: var(--secondary);
  }
}


.ul-custom {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}


textarea {
  padding:10px 15PX;
}

.default-border-radius{
  border-radius: 10px;
}


.forceWordWrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
 // hyphens:auto;
}

/* =============================================================================
   Buttons
   ========================================================================== */


.btn {

  border: none;
  // min-height: 44px;

  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  padding: .6rem 1rem .4rem;
  min-width: 100px;

  &.size_250_50 {
    width:250px;
    height:50px;
  }

  &.min_width_200 {
    min-width:200px;
  }


  &.btn-primary{    
    min-height: 50px;
    border: solid 1px var(--secondary);
    border-radius: 30px;
    background-color: var(--secondary);
    text-align: center;
    color: var(--white);

    //&:hover,
    //&:focus
    //{
    //  background-color: white !important;
    //  //border-color: var(--primary) !important;
    //  color: var(--secondary) !important;
    //}


    //&:active,
    //&.active
    //{
    //  //background-color: transparent !important;
    //  background-color: var(--secondary) !important;
    //  //border-color: var(--primary) !important;
    //  color: var(--primary) !important;
    //}
  }


  &.btn-secondary {
    border: 1px solid var(--accent);
    background-color: var(--white) !important;;
    color: var(--accent);

    &:hover,
    &:focus
    {
      background-color: var(--accent-dark) !important;
      //border-color: var(--primary) !important;
      color: var(--white) !important;
    }

    &:active,
    &.active
    {
      //background-color: transparent !important;
      background-color: var(--white) !important;
      border-color: var(--accent-dark) !important;
      color: var(--accent-dark) !important;
    }
  }

  &.btn-primary-outline{
    border: 1px solid var(--secondary);
    background-color: transparent;
    color: var(--secondary);

    &:hover,
    &:focus
    {
      background-color: var(--accent-dark) !important;
      //border-color: var(--primary) !important;
      color: var(--white) !important;
    }

    &:active,
    &.active
    {
      //background-color: transparent !important;
      background-color: var(--white) !important;
      border-color: var(--accent-dark) !important;
      color: var(--accent-dark) !important;
    }
  }


  &.btn-secondary-light {
    background-color: var(--secondary-light);
    color: var(--white);
  }

  //todo this i named wrong
  &.btn-dark-brown {
    background-color: var(--secondary-dark);
    color: var(--white);
  }

  &.btn-secondary-filled {
    background-color: var(--secondary);
    color: var(--white);
  }


  //&:hover, &:focus, &:active, &.active {
  //  background-color: var(--white);
  //}
}





.medium_width_view {
  max-width:732px;
}


/* =============================================================================
   iOS Fixes
   ========================================================================== */

/*
  Padding to fix iOS and Safari issues with content on bottom being cut off.
  This is not a Notch Issue.
  On iOS, when the bottom control bar is displayed (on iphones) the bar covers up the content.
  This is a known issue with Apple.
  iPads also have a similiar issue when calculating height of the content area.
 */
.ios-padding-fix,
.safari-padding-fix {
  padding-bottom: 80px;
  // background-color: red;
}




/* =============================================================================
   Icons
   ========================================================================== */
//i.fas, i.far {
//
//
//  &.sm {
//    color: var(--light-color-text) !important;
//    font-size: 1.4em;
//  }
//
//  &.lg {
//    font-size: 1.6em;
//  }
//}






/* =============================================================================
   Forms
   ========================================================================== */
//input[type="search"]::-webkit-search-decoration,
//input[type="search"]::-webkit-search-cancel-button,
//input[type="search"]::-webkit-search-results-button,
//input[type="search"]::-webkit-search-results-decoration { display: none; }
//input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
//input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
//
//
//.form-control, .custom-select {
//
//	&:focus {
//	}
//}
//
//.custom-control-input:focus ~ .custom-control-label::before {
//	box-shadow: none !important;
//}

.custom-input-group-text {
  background-color: var(--white);
  border-color:black var(--black);

  &:hover {
    cursor: pointer;
  }
}

.form-group .custom-select, .custom-control .custom-select {
  //background: #fff url(../images/ico-dropdown.png) no-repeat right 0.75rem center;
  cursor: pointer;
  //font-size: 1.4em;
  //line-height: 1.5em;
}


.form-group label, .custom-form-lable-1 {

  margin-bottom: 0;

  font-size: 18px;
  font-weight: 500;
}


.form-group .form-text-small {
  font-style: normal;
  font-weight: normal;
  color:var(--black);
}


.form-group .form-control, .custom-control .form-control, .form-group .custom-select, .custom-control .custom-select {

  height: 50px;

  border: 1px solid var(--black);

  font-size: 18px;
  font-weight: normal;

  color: var(--black);
}


.form-group .invalid-feedback {

  margin-bottom: 0;

  font-size: 14px;
}



.form-group small, .form-group .small {

  margin-bottom: 0;

  font-size: 14px;
  font-weight: 500;
  font-style:italic;
}


//
// Form field validation color overrides.
//
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: var(--black);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--black);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--black);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: var(--black) !important;
}





//
// Make the Checkbox bigger and change its color.
//

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
input[type="radio"] {
  width: 30px;
  height: 30px;
}


.custom-checkbox .custom-control-label{

  padding-top: 2px;
  line-height: 22px;
  font-size: 18px;
}

.custom-control-label:before{

  margin-left: 5px;

  border-color: var(--secondary);

  width: 20px;
  height: 20px;
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::before{

  //background-color:var(--white);
  background-color:var(--secondary);
  border-color: var(--secondary);
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--secondary);
  width: 30px;
  height: 30px;

  margin-top: -5px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--secondary);
  //box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
  box-shadow: none;
}





//
// Make the Radio bigger and change its color.
//
.custom-radio .custom-control-input:checked~.custom-control-label::before{

  background-color:var(--white);
  border-color: var(--secondary);
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color:var(--white);
  border-color: var(--secondary);
}

.custom-radio .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--secondary);
  width: 35px;
  height: 35px;

  margin-top: -7px;
  margin-left:-3px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='rgb(82, 138, 254)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  //background-image:url(../images/icons/ico-plus.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.custom-radio .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--secondary);
  box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
}


.form-error-message {
  font-size: 18px;
  color: var(--error);
}

.form-field-error-message {
  font-size: 16px;
  color: var(--error);
}


.form-field-custom-error-message-sm {
  font-size: 14px;
  color: var(--error);
}


.custom-textarea-1 {
  height:330px;
  max-width:825px;
  resize: none;
  background-color: var(--primary-light);
  border: solid 2px var(--secondary);
  color:var(--secondary);
  border-radius: 10px;
}


/* =============================================================================
   Tables
   ========================================================================== */
//table {
//  &.table {
//
//  }
//
//  &.table-striped {
//  }
//}



/* =============================================================================
   Report Layouts
   ========================================================================== */

.report-type-1-layout {

  table {
    &.report-table-type-1 {
      margin: 0 auto;
      font-size: 12px !important;

      th {
        height: 100%;
        // min-width: 120px;
        padding: 10px 10px;
        text-align: center;
        font-weight: 500;
        color: var(--secondary);
      }

      .report-table-header-row {
        border-top: 2px solid var(--accent-background);
        border-bottom: 2px solid var(--accent-background);
      }

      tbody tr:nth-child(odd) {
        //border-bottom: 1px solid var(--accent-background);
        background: var(--primary-light);
      }

      tbody tr:nth-child(even) {
        //border-bottom: 1px solid var(--accent-background);
        background: transparent;
      }

      td {
        padding: 20px 10px;
        text-align: center;
      }

      .accent-text-1 {
        color: var(--secondary-dark);
      }
    }
  }


  .report-result-section {

    overflow-x: auto;
    overflow-y: hidden;
  }

  .download-report-button {
    height:45px;
    padding-bottom:1px;
  }
}


.table-row {
  &:hover {
    cursor: pointer;
    background-color: var(--accent-dark) !important;
  }
}


.fixed-width-30 {
  width:30px;
  min-width:30px;
  max-width:30px;
}

.fixed-width-50 {
  width:50px;
  min-width:50px;
  max-width:50px;
}

.fixed-width-75 {
  width:75px;
  min-width:75px;
  max-width:75px;
}

.fixed-width-100 {
  width:100px;
  min-width:100px;
  max-width:100px;
}

.fixed-width-120 {
  width:120px;
  min-width:120px;
  max-width:120px;
}

.fixed-width-130 {
  width:130px;
  min-width:130px;
  max-width:130px;
}

.fixed-width-150 {
  width:150px;
  min-width:150px;
  max-width:150px;
}

.fixed-width-175 {
  width:175px;
  min-width:175px;
  max-width:175px;
}

.fixed-width-200 {
  width:200px;
  min-width:200px;
  max-width:200px;
}

.fixed-width-225 {
  width:225px;
  min-width:225px;
  max-width:225px;
}

.fixed-width-250 {
  width:250px;
  min-width:250px;
  max-width:250px;
}

.fixed-width-300 {
  width:300px;
  min-width:300px;
  max-width:300px;
}

.fixed-width-350 {
  width:350px;
  min-width:350px;
  max-width:350px;
}



/* =============================================================================
   Carousel
   ========================================================================== */

.carousel-indicators {
  //background-color: white;
  bottom:-45px;


  li {
    height:15px;
    width:15px;
    background: var(--accent);
    border-radius: 15px;

    border-top:none;
    border-bottom:none;
  }
}

.custom-carousel {
  border-radius: 0px 0px 0px 80px;
  overflow: hidden;
}


@media only screen and (min-width: 1199px) {
  .custom-carousel {
    height: 530px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1198px) {
 .custom-carousel {
   height: 530px;
 }
}


@media only screen and (min-width: 768px) and (max-width: 899px) {
 .custom-carousel {
   height: 750px;
 }
}

// BAD
//@media only screen and (min-width: 650px) and (max-width: 767px) {
//  .custom-carousel {
//    height: 1200px;
//  }
//}



/* =============================================================================
   Modal
   ========================================================================== */

// THIS BREAKS MODALS. DO NOT USE.
//@media (min-width: 576px){
//  .modal-dialog {
//    max-width: 575px;
//    margin: 1.75rem auto;
//  }
//}

.modal-content {
  color: var(--black);
  border-radius: 10px;
  box-shadow: var(--box-shadow-dark); //rgba(73, 39, 40, 0.58);
}

.modal-wide {
  width:79%;
  max-width:79%;
}

.ami-modal {

  &.ami-modal-type-1-border {
    box-shadow: var(--box-shadow-light);
    border: solid 1px var(--light-gray);
  }

  .ami-modal-type-1-body {
    background-color: var(--white);
    border-radius: 10px;
  }

  .ami-modal-type-2-body {
    background-color: var(--white);
    border-radius: 10px;
  }

  .ami-modal-close-btn {
    position:absolute;
    top:10px;
    right: 10px;
    z-index: 1;
  }

  .ami-modal-title {
    padding-top:25px;
  }
}


/* =============================================================================
   Date Picker
   ========================================================================== */

.react-datepicker-popper {
  z-index: 10 !important;
}

.ami-date-picker .react-datepicker__input-container input {
  width: 100%;
  border:  1px solid black;
  border-radius: 4px;
  height: 50px;
}

/* =============================================================================
   Header
   ========================================================================== */



/* =============================================================================
   Main
   ========================================================================== */





/* =============================================================================
   Footer
   ========================================================================== */





/* =============================================================================
   Media Queries
   ========================================================================== */


//@media only screen and (max-width: 1199px) {
//}
//
//@media only screen and (max-width: 1024px) {
//}
//
//@media only screen and (max-width: 991px) {
//}
//
//@media only screen and (max-width: 767px) {
//  h1, .h1 {
//  }
//
//  h2, .h2 {
//  }
//
//  h3, .h3 {
//  }
//
//  h4, .h4 {
//  }
//
//  h5, .h5 {
//  }
//
//  h6, .h6 {
//  }
//
//
//  p {
//
//    &.lg {
//    }
//
//    &.md {
//    }
//  }
//}

//@media only screen and (max-width: 575px) {
//  h1, .h1 {
//    font-size: 35px;
//  }
//}


//@media only screen and (max-width: 360px) {
//}


//@media only screen and (max-width: 300px) {
//}

//@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//}

// @media only screen and (max-width: 900px) {

//   .custom-carousel {
//     height: 1300px;
//   }
// }

// @media only screen and (max-width: 450px) {

//   .custom-carousel {
//     height: 1200px;
//   }
// }
