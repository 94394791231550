.footer {

  //position: relative;
  //bottom: 0;
  //top:1px;
  //height:500px;

  //margin-top:20px;
  //margin-bottom:0;
  //
  //padding-bottom: 20px;
  //padding-bottom: calc( env(safe-area-inset-bottom, 0) + 20px);   // iOS Notch Issue

  box-shadow: 0 50vh 0 50vh var(--primary);
  //border-top: solid 1px var(--primary-light);


  background-color: var(--primary);

  padding-bottom: 0;

  .rowSpacing {
    padding-top: 22px;
    padding-bottom: 5px;
  }


  //ul {
  //  li {
  //    font-weight: normal;
  //  }
  //}

  .link {
    color: var(--secondary);
    text-decoration: underline;
  }
}



