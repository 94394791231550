.cookieContent {
  position: fixed;
  bottom: 0;
  width: 100%;

  z-index: 601;

  overflow: hidden;

  font-size: 14px;

  background-color: var(--light-gray);

  box-shadow: var(--box-shadow-dark);

  color: var(--black);
}


.container {
  display: flex;
  flex-direction: row;

  padding-top: 10px;
}



.infoBox {

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--secondary-dark); ////rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  ::-webkit-scrollbar-track {
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: var(--isi-cookie-box-shadow);
  }
}


.contentArea {
  overflow: hidden;

  padding:5px
}

.opened {
  transition: max-height 0.75s ease-out;
  max-height:229px;
}

.closed {
  max-height:80px;
  transition: max-height 0.75s ease-in;
}



.expandCtlContainer {

  height:30px;

  padding-top:5px;
}


.submitSection {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 15px;


  padding-top: 10px;
  padding-bottom: 10px;
}



.flipVertical {
  transform: scaleY(-1);
}




@media only screen and (max-width: 1024px) {

  .container {
    flex-direction: column;
  }

  .submitSection {
    padding-top: 0;
  }
}




@media only screen and (max-width: 768px) {

  .contentArea {
    overflow-y: scroll;
  }

  .expandCtlContainer {

    display: none;
  }

  .contentArea {
    max-height:120px;
    padding: 15px;
    background-color: white;
  }

  .submitSection {
    padding-top: 10px;
  }
}




